import React from 'react';
import ListArticle from 'src/interface/articles/list-article';
import { BtnNextLink } from 'src/interface/basics/links';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionTitle, SectionText, SectionBody } from 'src/interface/basics/sections';

const ContactSectionProjects = ({ className = '' }) => {
  return (
		<Section className={`py-8 ${className}`}>
			<Container>
				<Row>
					<Col lg={4}>
						<SectionHeader className='mb-4'>
							<SectionTitle>Projects we like</SectionTitle>
							<SectionText>We typically consult on the following projects though we're always happy to chat.</SectionText>
						</SectionHeader>
					</Col>
					<Col lg={8}>
						<SectionBody>
							<Row>
			          <Col md={6}>
			            <ListArticle
			              icon='store'
			              title='Marketplaces'
			              description="Digital marketplaces like AirBnB or Etsy let anyone become a verified buyer / seller of physical goods or services."
			              className='mb-4'
			            />
			          </Col>
			          <Col md={6}>
			            <ListArticle
			              icon='user-friends'
			              title='Social Networking'
			              description="From likes to messaging or activity feeds, social networking tools are common in nearly every modern application."
			              className='mb-4'
			            />
			          </Col>
			          <Col md={6}>
			            <ListArticle
			              icon='stream'
			              title='Data Aggregation'
			              description="Data is everywhere but it's often disconnected and therefore useless. Data aggregation services compile, organize and store it intelligently."
			              className='mb-4'
			            />
			          </Col>
			          <Col md={6}>
			            <ListArticle
			              icon='brackets-curly'
			              title='API Services'
			              description="API services make your data accessible to third parties, or across platforms, in an organized, secure and usable fashion."
			              className='mb-4'
			            />
			          </Col>
			          <Col md={6}>
			            <ListArticle
			              icon='taxi'
			              title='On-Demand Services'
			              description="The gig-economy is being driven by on-demand services ranging from Uber to DoorDash and industry-specific players."
			              className='mb-4'
			            />
			          </Col>
			          <Col md={6}>
			            <ListArticle
			              icon='drone-alt'
			              title='Internet of Things'
			              description="The fusion of physical products with cloud-based software allows anything to be tracked, monitored, and controlled remotely."
			              className='mb-4'
			            />
			          </Col>
	              <Col md={6}>
		              <ListArticle
		                icon='tachometer-alt-fast'
		                title='Software as a Service'
		                description="Software as a service is everywhere these days offering targeted, cloud-based solutions that integrate with other tools."
		                className='mb-5'
		              />
		            </Col>
		            <Col md={6}>
		              <ListArticle
		                icon='browser'
		                title='Platform as a Service'
		                description="Platforms as a service are simplifying the way we access and publish to the web through comprehensive cloud-based solutions."
		                className='mb-5'
		              />
		            </Col>
			          <Col md={6}>
			            <ListArticle
			              icon='browser'
			              title='Administrative Tools'
			              description="Every business is unique and sometimes custom administrative platforms are needed to house all your data in one place."
			              className='mb-4'
			            />
			          </Col>
			          <Col md={6}>
			            <ListArticle
			              icon='lightbulb'
			              title='Other Ideas'
			              description="It's impossible to list every idea but generally speaking we love building things so let's chat and see if it's a fit."
			              className='mb-4'
			            />
			          </Col>
		          </Row>
	          </SectionBody>
					</Col>
				</Row>
			</Container>
		</Section>
  )
}

export default ContactSectionProjects;
