import React, { useEffect, useState } from 'react';
import { withDark } from 'src/contexts/dark-context';
import { LoadScriptNext, GoogleMap, Marker } from '@react-google-maps/api';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';
import DayMap from 'src/utils/maps/day-map';
import MidnightMap from 'src/utils/maps/midnight-map';

const GOOGLE_KEY = process.env.GATSBY_GOOGLE_KEY;

const GoogleMapWrapper = (props) => {
	const { darkContext = {}, latitude = 39.7526726, longitude = -105.0659112, styles = [], className = '' } = props;
	const { dark } = darkContext;
	const position = { lat: latitude, lng: longitude };
	const home = { lat: 39.7555221, lng: -105.0545968 };

	const options = {
		zoom: 15,
		zoomControl: false,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		scrollwheel: false,
		styles: dark ? MidnightMap() : DayMap()

	}


	// Render
	return (
		<div className={`map-wrapper ${className}`}>
			<LoadScriptNext googleMapsApiKey={GOOGLE_KEY}>
				<GoogleMap center={position} options={options} mapContainerClassName='google-map'>
					<Marker position={home} animation='DROP'/>
				</GoogleMap>
			</LoadScriptNext>
		</div>
	)
}

export default withDark(GoogleMapWrapper);


// Docs
// https://react-google-maps-api-docs.netlify.app/
