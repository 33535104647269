import React from 'react';

import ContactHeader from 'src/components/contact/contact-header';
import ContactSectionGuarantee from 'src/components/contact/contact-section-guarantee';
import ContactSectionForm from 'src/components/contact/contact-section-form';
import ContactSectionProjects from 'src/components/contact/contact-section-projects';
import ContactSectionFaq from 'src/components/contact/contact-section-faq';

import MktRoute from 'src/interface/routes/mkt-route';
import MktNav from 'src/interface/navs/mkt-nav';
import MktPrefooter from 'src/interface/prefooters/mkt-prefooter';
import MktFooter from 'src/interface/footers/mkt-footer';

const ContactPage = () => {

  return (
    <MktRoute title='Mile Hi Labs | Contact us' description="Whether you have a startup idea or a specific project in mind, we'd love to chat.">
      <MktNav />
      <ContactHeader />
      <main className='relative mvh-50'>
        <ContactSectionGuarantee />
        <ContactSectionForm className='bg-gray-50'/>
        <ContactSectionProjects />
        <ContactSectionFaq className='bg-gray-50'/>
        <MktPrefooter />
      </main>
      <MktFooter />
    </MktRoute>
  )
}

export default ContactPage;
