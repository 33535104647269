import React from 'react';
import { ButtonText } from 'src/interface/basics/buttons';
import { FadIcon } from 'src/interface/vendors/fa-wrapper';

const Empty = ({ className, children }) => (
  <div className={`empty-block ${className}`}>
    {children}
  </div>
)

const EmptyHeader = ({ icon, className = '', children }) => (
  <div className={`empty-header ${className}`}>
    {icon && <FadIcon icon={icon} size='2x'/>}
    {children}
  </div>
)

const EmptyBody = ({ title, description, className = '', children }) => (
  <div className={`empty-body ${className}`}>
    {title && <EmptyTitle>{title}</EmptyTitle>}
    {description && <EmptyText>{description}</EmptyText>}
    {children}
  </div>
)

const EmptyTitle = ({ className = '', children }) => (
  <h4 className={`empty-title ${className}`}>
    {children}
  </h4>
)

const EmptyText = ({ className = '', children }) => (
  <h6 className={`empty-text ${className}`}>
    {children}
  </h6>
)

export { Empty, EmptyHeader, EmptyBody, EmptyTitle, EmptyText }
