import React from 'react';
import { Empty, EmptyHeader, EmptyBody, EmptyTitle, EmptyText } from 'src/interface/basics/empties';
import { FadIcon } from 'src/interface/vendors/fa-wrapper';

const PreviewEmpty = ({ icon, title, description, className = '', children }) => (
  <Empty className={`preview-empty-block ${className}`}>
  	{icon && <EmptyHeader icon={icon} />}
  	<EmptyBody>
    	{title && <EmptyTitle>{title}</EmptyTitle>}
    	{description && <EmptyText>{description}</EmptyText>}
    	{children}
  	</EmptyBody>
  </Empty>
)

export default PreviewEmpty;
