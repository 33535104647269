import React from 'react';
import ListArticle from 'src/interface/articles/list-article';
import { BtnNextLink } from 'src/interface/basics/links';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionPretitle, SectionTitle, SectionText, SectionBody } from 'src/interface/basics/sections';

const ContactSectionGuarantee = ({ className = '' }) => {
  return (
		<Section className={`py-8 ${className}`}>
			<Container>
				<Row>
					<Col lg={4}>
						<SectionHeader className='mb-4'>
							<SectionTitle>Our Guarantee to you</SectionTitle>
							<SectionText>Customer satifaction is a key tenet of our organization.</SectionText>
						</SectionHeader>
					</Col>
          <Col lg={{span: 7, offset: 1}}>
          	<SectionBody>
	            <ListArticle
	              icon='fire'
	              title='Exceptional Performance'
	              description="Everything we build uses modern technology we've already vetted so you get exceptional performance from first paint to critical operations."
	              className='mb-4'
	            />
	            <ListArticle
	              icon='globe'
	              title='99% Uptime'
	              description="If we built your cloud computing infrastructure, we'll guarantee 99.9% uptime performance with around the clock service and monitoring for outages."
	              className='mb-4'
	            />
	            <ListArticle
	              icon='bug'
	              title='Bug-Free Software'
	              description="We install bug reporting software in all our projects and will fix any bugs related to our code free of charge."
	              className='mb-4'
	            />
	          </SectionBody>
          </Col>
				</Row>
			</Container>
		</Section>
  )
}

export default ContactSectionGuarantee;
