import React, { useEffect, useState } from 'react';
import { withStore, BaseAdapter, BaseSerializer, JsonApiError } from '@mile-hi-labs/react-data';
import { withToast } from 'src/contexts/toast-context';
import PreviewEmpty from 'src/interface/empties/preview-empty';
import { NextButton, TextNextButton } from 'src/interface/basics/buttons';
import { Form, FormGroup, FormControl, FormSelect, FormTextArea } from 'src/interface/basics/forms';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionTitle, SectionText, SectionBody } from 'src/interface/basics/sections';
import { PhoneMaskedInput } from 'src/interface/vendors/masked-wrapper';
import { timeout } from 'src/utils/helpers';

const CONTACT_URL = `${process.env.GATSBY_SERVER_URL}/contact`;

const ContactSectionForm = ({ store, toast, className = '' }) => {
	const [ firstName, setFirstName ] = useState('');
	const [ lastName, setLastName ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ phone, setPhone ] = useState('');
	const [ subject, setSubject ] = useState('General');
	const [ message, setMessage ] = useState('');
	const [ success, setSuccess ] = useState(false);
	const [ taskRunning, setTaskRunning ] = useState(false);
	const subjects = ['General Inquiry', 'Project Inquiry', 'Job Inquiry', 'Medi Inquiry'];


	// Hoooks
	useEffect(() => {
		return () => resetForm();
	}, [])


	// Methods
	async function submitForm() {
		try {
			setTaskRunning(true);
			let data = BaseSerializer.serialize({firstName: firstName, lastName: lastName, email: email, phone: phone, subject: subject, message: message });
			await BaseAdapter.networkCall(CONTACT_URL, BaseAdapter.options('POST', data));
			await timeout(300);
			setSuccess(!success);
			toast.showSuccess('We received your message!');
		} catch (e) {
			let formattedError = JsonApiError.format(e);
			toast.showError(formattedError);
		} finally {
			setTaskRunning(false);
		}
	}

	function resetForm() {
		setFirstName('');
		setLastName('');
		setEmail('');
		setPhone('');
		setSubject(subjects[0]);
		setMessage('');
		setSuccess(!success);
	}


	// Render
  return (
		<Section id='contact_form' className={`py-8 ${className}`}>
      <Container>
        <Row>
          <Col lg={4}>
          	<SectionHeader className='mb-4'>
            	<SectionTitle>Message Us</SectionTitle>
            	<SectionText>Send us a message and someone from our team will be in touch.</SectionText>
          	</SectionHeader>
          </Col>
          <Col lg={7}>
          	<SectionBody>
		          {success ? (
								<PreviewEmpty
									icon='check-circle'
									title='Form Submitted'
									description='Thanks for your submission. Someone will be in touch with you shortly.'
									className='bg-white s-bottom-3'>
									<div className='text-center py-2'>
										<TextNextButton onClick={() => resetForm()} className='btn-text-primary'>Reset Form</TextNextButton>
									</div>
								</PreviewEmpty>
							) : (
								<Form onSubmit={e => e.preventDefault()}>
									<Row>
										<Col md={6}>
											<FormGroup label='First Name'>
												<FormControl
													type='text'
													value={firstName}
													placeholder='First Name'
													onChange={e => setFirstName(e.target.value)}
												/>
											</FormGroup>
										</Col>

										<Col md={6}>
											<FormGroup label='Last Name'>
												<FormControl
													type='text'
													value={lastName}
													placeholder='Last Name'
													onChange={e => setLastName(e.target.value)}
												/>
											</FormGroup>
										</Col>

										<Col md={6}>
											<FormGroup label='Email'>
												<FormControl
													type='email'
													value={email}
													placeholder='Your Email'
													onChange={e => setEmail(e.target.value)}
												/>
											</FormGroup>
										</Col>

										<Col md={6}>
											<FormGroup label='Phone Number' help='Optional'>
												<PhoneMaskedInput
													type='phone'
													value={phone}
													placeholder='(303) 444-2226'
													onChange={e => setPhone(e.target.value)}
												/>
											</FormGroup>
										</Col>
									</Row>

									<FormGroup label='Subject'>
										<FormSelect
											type='text'
											value={subject}
											placeholder='Subject'
											options={subjects}
											onChange={e => setSubject(e.target.value)}
										/>
									</FormGroup>

									<FormGroup label='Message' help='Max. 500 characters'>
										<FormTextArea
											value={message}
											placeholder='What would you like to share?'
											onChange={e => setMessage(e.target.value)}
										/>
									</FormGroup>
									<div className='py-2'>
										<NextButton taskRunning={taskRunning} onClick={() => submitForm()}>Send Message</NextButton>
									</div>
								</Form>
							)}
						</SectionBody>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default withStore(withToast(ContactSectionForm));
