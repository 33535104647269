import React from 'react';
import MapWrapper from 'src/interface/vendors/map-wrapper';
import { Header, HeaderInner, HeaderBody, HeaderPretitle, HeaderTitle, HeaderSubtitle, HeaderText, HeaderBtns } from 'src/interface/basics/headers';
import { AnchorLink } from 'src/interface/basics/links';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';
import ShapesAnimation from 'src/interface/animations/shapes-animation';

const ContactHeader = () => {

  return (
    <Header className='py-5 gradient-primary-accent'>
    	<HeaderInner className='mvh-40'>
	      <Container className='z-20' fluid='md'>
	        <Row>
	          <Col lg={7}>
	            <HeaderBody className='animate fade-in-up delay-200'>
	              <HeaderPretitle>Contact Us</HeaderPretitle>
	              <HeaderTitle>Get in Touch</HeaderTitle>
	              <HeaderText>
	              	Whether you have a startup idea or a specific project in mind, we'd love to chat.
	              	It's never too early to get the ball rolling and we're always looking for ways to help others.
              	</HeaderText>
	              <HeaderBtns className='animate fade-in-up delay-400'>
	              	<AnchorLink to='/contact#contact_form' className='btn btn-opaque-white btn-lg'>
	              		Send Message<FalIcon icon='long-arrow-right' className='ml-2'/>
              		</AnchorLink>
	              </HeaderBtns>
	            </HeaderBody>
	          </Col>
	        </Row>
	      </Container>
      </HeaderInner>
      <ShapesAnimation />
    </Header>
  )
}

export default ContactHeader;
