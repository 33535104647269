import React, { useEffect } from 'react';
import RbForm from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { ButtonText } from 'src/interface/basics/buttons';
import { TagsWrapper } from 'src/interface/basics/tags';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';
import { capitalize, stringToUnderscore, underscoreToString } from 'src/utils/transforms';

const Form = ({ className = '', onSubmit, children }) => (
  <form onSubmit={onSubmit} className={className}>
    {children}
  </form>
)

const FormGroup = ({ id, label, className = '', children }) => (
	<RbForm.Group controlId={id ? id : stringToUnderscore(label)} className={className}>
    {label && <FormLabel label={label}/>}
		{children}
	</RbForm.Group>
)


const FormLabel = ({ label, className = '', children }) => (
	<RbForm.Label className={`gray-700 ${className}`}>
		{label && label}
		{children}
	</RbForm.Label>
)

const FormControlPrepend = ({ icon, loading, className = '', children }) => (
  <InputGroup className={className}>
    <InputGroup.Prepend>
      <InputGroup.Text>{loading ? <Spinner role='status' animation='border' size={'sm'} />  : <FalIcon icon={icon} />}</InputGroup.Text>
    </InputGroup.Prepend>
    {children}
  </InputGroup>
)

const FormControlAppend = ({ icon, className = '', children }) => (
  <InputGroup className={className}>
    {children}
    <InputGroup.Append>
      <InputGroup.Text><FalIcon icon={icon} /></InputGroup.Text>
    </InputGroup.Append>
  </InputGroup>
)

const FormControl = ({ type = 'text', autoFocus, placeholder, value, onClick, onChange, onKeyDown, onFocus, onBlur, readOnly, className = '' }) => (
  <RbForm.Control
    type={type}
    placeholder={placeholder}
    value={value}
    autoFocus={autoFocus}
    onChange={onChange}
    onClick={onClick}
    onKeyDown={onKeyDown}
    onFocus={onFocus}
    onBlur={onBlur}
    readOnly={readOnly}
    className={className}
  />
)

const FormControlDiv = ({ id, placeholder, value ='', onClick, onFocus }) => (
  <div id={id} className='form-control div' onClick={onClick} onFocus={onFocus}>
    {value ? <TagsWrapper>{value}</TagsWrapper> : <span className='gray-color'>{placeholder}</span>}
  </div>
)

const FormFile = ({ placeholder, value = '', onChange }) => (
  <RbForm.File placeholder={placeholder} value={value} onChange={onChange} />
)

const FormSelect = ({ value = -1, options = [], placeholder, onChange }) => (
  <RbForm.Control as='select' value={value} onChange={onChange}>
    <option disabled value={-1}>{placeholder}</option>
    {options.map(option => <option key={option} value={option}>{option}</option>)}
  </RbForm.Control>
)

const FormTextArea = ({ placeholder, rows = 5, value, onChange, onFocus }) => (
  <RbForm.Control as='textarea' placeholder={placeholder} rows={rows} defaultValue={value} onChange={onChange} onFocus={onFocus} />
)

const FormText = ({ className = '', children }) => (
  <RbForm.Text className={`gray-400 ${className}`}>{children}</RbForm.Text>
)

export {
	Form,
  FormGroup,
  FormLabel,
  FormControlPrepend,
  FormControlAppend,
  FormControl,
  FormControlDiv,
  FormFile,
  FormSelect,
  FormTextArea,
  FormText,
}

// Docs
// https://react-bootstrap.github.io/components/forms/
